import React, { useState, useEffect } from "react";
import styles from "./Toggle.module.scss";

const Toggle = () => {
    const [dark, setDark] = useState(getMode);
    const bodyEl = typeof window !== `undefined` ? document.getElementsByTagName("body")[0] : null;

    useEffect(() => {
      localStorage.setItem("dark", JSON.stringify(dark));
      dark ? bodyEl.classList.add("dark") : bodyEl.classList.remove("dark");
        
    }, [dark]);

    function getMode() {
      const savedmode =
        typeof window !== `undefined`
          ? JSON.parse(localStorage.getItem("dark"))
          : false;
      return savedmode || false;
    }

  return (
    <div className={styles["switch__container"]}>
      <label>
        <input
          id="DarkModeSwitcher"
          checked={dark}
          // defaultChecked={!isDarkModeOn}
          className={styles["toggle-checkbox"]}
          type="checkbox"
        ></input>
        <div
          className={styles["toggle-slot"]}
          onClick={() => setDark((prevMode) => !prevMode)}
        >
          <div className={styles["sun-icon-wrapper"]}>
            <div
              class="iconify sun-icon"
              data-icon="feather-sun"
              data-inline="false"
            ></div>
          </div>
          <div className={styles["toggle-button"]}></div>
          <div className={styles["moon-icon-wrapper"]}>
            <div
              class="iconify moon-icon"
              data-icon="feather-moon"
              data-inline="false"
            ></div>
          </div>
        </div>
      </label>
    </div>
  );
};

export default Toggle;
